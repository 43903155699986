<template>
  <v-container fluid>
    <v-card class="my-1" color="orange">
      <v-card-title class="justify-center">
       Additional Services: All Files for Home_ID: {{ currentRouteName }}
      </v-card-title>
    </v-card>
    <v-text-field
      outlined
      counter
      v-model="search"
      append-icon="mdi-magnify"
      label="Search Files"
      single-line
      :rules="[
        (v) =>
          (v && v.length >= 3) || 'Description must be 3 characters or more',
      ]"
    ></v-text-field>
    <v-data-table
      mobile-breakpoint="0"
      class="elevation-5 table my-5"
      v-model="selected"
      show-select
      :headers="headers"
      :items="items"
      :search="searchTrigger"
      :expanded.sync="expanded"
      :single-expand="singleExpand"
      show-expand
      item-key="id"
      multi-sort
      :loading="myloadingvariable"
      loading-text="Loading Images... Please wait"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, -1],
      }"
      :items-per-page="10"
    >
      <template v-for="header in headers" #[`item.${header.value}`]="{ value }">
        <v-chip :key="header.id" :color="CheckNull(value)">
          {{ value }}
        </v-chip>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-text-field
            class="ma-2"
            v-model="item.comments"
            append-icon="mdi-file-edit"
            label="Editing/Fix Comments"
            hide-details
          ></v-text-field>
          <v-text-field
            class="ma-2"
            v-model="item.editorimagerefrencelink"
            append-icon="mdi-file-edit"
            label="Completed Image Reference Link (Google Drive with Current Editor)"
            hide-details
          ></v-text-field>
        </td>
      </template>
    </v-data-table>
    <v-text-field
      class="ma-2"
      v-model="generaleditingcomments"
      append-icon="mdi-file-edit"
      label="General Editing Comments"
      hide-details
    ></v-text-field>
    <v-btn class="elevation-5" block color="green" @click="SendSelectedFiles">
      Send Selected Files To Editing Vendor
      <v-icon dark>mdi-file</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
var axios = require("axios");
export default {
  name: "App",
  computed: {
    currentRouteName() {
      return this.$route.params.id;
    },
    searchTrigger() {
      if (this.search.length >= 3) {
        return this.search;
      }
    },
  },
  created() {
    this.GetImages();
  },
  methods: {
    async GetImages() {
      this.myloadingvariable = true;
      let url =
        "https://apibox.hommati.cloud/ajax/photo_editing/list_contents/" +
        this.currentRouteName;
      const res = await axios.get(url, {});
      if (res.data.status != "error") {
        Object.keys(res.data.contents).forEach((link, index) => {
          this.items.push({
            id: index,
            full_bucket_url: link,
            last_uploaded: res.data.contents[link].LastModified,
            size: parseInt(res.data.contents[link].Size),
          });
        });
        this.makeHeaders();
        this.myloadingvariable = false;
      }
    },
    SendSelectedFiles() {
      var edit_request_list = []
      this.selected.forEach((item)=>{
        edit_request_list.push({
          full_bucket_url: item.full_bucket_url,
          editing_request_comments: item.comments
        })
      })
      console.log(edit_request_list)
      console.log(this.generaleditingcomments);
    },
    makeHeaders() {
      Object.keys(this.items[0]).forEach((header) => {
        this.headers.push({
          text: header.toString(),
          value: header,
          align: "center",
        });
      });
    },
    CheckNull(CheckIfNull) {
      if (CheckIfNull === null || CheckIfNull === "") return "red";
      else return "green";
    },
  },
  data: () => ({
    selected: [],
    expanded: [],
    myloadingvariable: false,
    singleExpand: true,
    search: "",
    headers: [],
    items: [],
    generaleditingcomments: "",
  }),
};
</script>
<style>
.v-data-table-header {
  background-color: orange;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.2);
}
.v-data-footer {
  background-color: orange;
}
table td {
  border: 1px solid black;
  font-weight: bold;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 10px;
}
table th {
  border: 1px solid black;
}
</style>
