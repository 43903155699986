import Vue from "vue";
import VueRouter from "vue-router";
import FixView from "../views/FixRequest.vue";
import AdditionalView from "../views/AdditionalServices.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/FixRequest/:id",
    name: "FixRequest",
    component: FixView,
  },
  {
    path: "/AdditionalServices/:id",
    name: "AdditionalService",
    component: AdditionalView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
