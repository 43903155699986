import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"my-1",attrs:{"color":"orange"}},[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" Additional Services: All Files for Home_ID: "+_vm._s(_vm.currentRouteName)+" ")])],1),_c(VTextField,{attrs:{"outlined":"","counter":"","append-icon":"mdi-magnify","label":"Search Files","single-line":"","rules":[
      (v) =>
        (v && v.length >= 3) || 'Description must be 3 characters or more',
    ]},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDataTable,{staticClass:"elevation-5 table my-5",attrs:{"mobile-breakpoint":"0","show-select":"","headers":_vm.headers,"items":_vm.items,"search":_vm.searchTrigger,"expanded":_vm.expanded,"single-expand":_vm.singleExpand,"show-expand":"","item-key":"id","multi-sort":"","loading":_vm.myloadingvariable,"loading-text":"Loading Images... Please wait","footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50, -1],
    },"items-per-page":10},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:`item.${header.value}`,fn:function({ value }){return [_c(VChip,{key:header.id,attrs:{"color":_vm.CheckNull(value)}},[_vm._v(" "+_vm._s(value)+" ")])]}}}),{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c(VTextField,{staticClass:"ma-2",attrs:{"append-icon":"mdi-file-edit","label":"Editing/Fix Comments","hide-details":""},model:{value:(item.comments),callback:function ($$v) {_vm.$set(item, "comments", $$v)},expression:"item.comments"}}),_c(VTextField,{staticClass:"ma-2",attrs:{"append-icon":"mdi-file-edit","label":"Completed Image Reference Link (Google Drive with Current Editor)","hide-details":""},model:{value:(item.editorimagerefrencelink),callback:function ($$v) {_vm.$set(item, "editorimagerefrencelink", $$v)},expression:"item.editorimagerefrencelink"}})],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c(VTextField,{staticClass:"ma-2",attrs:{"append-icon":"mdi-file-edit","label":"General Editing Comments","hide-details":""},model:{value:(_vm.generaleditingcomments),callback:function ($$v) {_vm.generaleditingcomments=$$v},expression:"generaleditingcomments"}}),_c(VBtn,{staticClass:"elevation-5",attrs:{"block":"","color":"green"},on:{"click":_vm.SendSelectedFiles}},[_vm._v(" Send Selected Files To Editing Vendor "),_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-file")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }